<template>
  <section class="pricing">
    <Banner :products="getProducts" @setActive="setActive" />
    <section class="container pricing-container">
      <section v-if="!active">
        <Placeholder class="placeholder-sm" />
        <p class="text__xl text__center text__bold text__pine placeholder-lg">Select a Product</p>
      </section>
      <section v-else class="pricing-tables__grid">
        <Pricing
          v-for="(pricing, index) in active.prices"
          :key="`${active.link}-${index}`"
          :pricing="pricing"
        />
      </section>
      <section class="margin__md">
        <h2 class="text__center padding__sm">Ready to Order?</h2>
        <div class="flex__center padding__sm">
          <router-link to="/print-job" class="btn btn__yellow" name="New print job">Submit a Request</router-link>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "./Banner";
import Placeholder from "./Placeholder";
import Pricing from "./Pricing";
export default {
  components: {
    Banner,
    Placeholder,
    Pricing
  },
  metaInfo: {
    title: "Products Pricing | Printzilla"
  },
  data() {
    return {
      active: null
    };
  },
  computed: {
    ...mapGetters(["getProducts"])
  },
  methods: {
    setActive(val) {
      this.active = val;
    }
  }
};
</script>

<style scoped lang="scss">
.pricing {
  @include grid($cols: 1fr);
}
.pricing-container {
  padding-top: 75px;
  padding-bottom: 75px;
}
.pricing-tables__grid {
  @include grid($cols: 1fr, $col-gap: 50px, $row-gap: 50px);
}
.placeholder-lg {
  display: none;
}
@media (min-width: $md) {
  .pricing {
    grid-template-columns: 2fr 3fr;
  }
  .placeholder-sm {
    display: none;
  }
  .placeholder-lg {
    display: block;
  }
}
@media (min-width: $lg) {
  .pricing {
    grid-template-columns: 1fr 2fr;
  }
  .pricing-tables__grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $xl) {
  .pricing {
    grid-template-columns: 1fr 3fr;
  }
}
</style>