<template>
  <section>
    <div class="banner">
      <div class="container">
        <div class="flex__col">
          <h1 class="text__center">Flat Branch Print Center</h1>
        </div>
      </div>
    </div>
    <section class="container">
      <div class="prints margin__md">
        <h2 class="padding__sm text__center">Please Your Partners With In-House Print Materials</h2>
        <p
          class="text__md padding__sm text__center"
        >Choose from a multitude of products to fit your needs and leave a lasting impression.</p>
        <Products />
      </div>
    </section>
    <Directions />
  </section>
</template>

<script>
import Products from "./Products";
import Directions from "./Directions";
export default {
  components: {
    Products,
    Directions
  },
  metaInfo: {
    title: "Home | Printzilla"
  },
  data() {
    return {
      first: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      },
      second: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      },
      third: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      },
      fourth: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      }
    };
  },
  created() {
    if (window.innerWidth >= 992) {
      this.first.delay = 100;
      this.second.delay = 300;
      this.third.delay = 500;
      this.fourth.delay = 700;
    }
  }
};
</script>

<style scoped lang="scss">
.banner {
  background: linear-gradient(
      rgba(159, 212, 172, 0.8),
      rgba(159, 212, 172, 0.5)
    ),
    url("https://cdn.fbhl.com/Print-Center/assets/print-examples.jpg");
  padding: 200px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  * {
    color: $pine;
  }
}
.prints {
  @include grid($cols: 1fr);
}
@media (min-width: $md) {
  .banner {
    padding: 250px 0;
  }
}
</style>