<template>
  <nav>
    <router-link to="/" @click.native="startSetNavToggled(false)">Home</router-link>
    <router-link to="/print-job" @click.native="startSetNavToggled(false)">Print Job</router-link>
    <router-link to="/pricing" @click.native="startSetNavToggled(false)">Pricing</router-link>
    <router-link to="/products" @click.native="startSetNavToggled(false)">Products</router-link>
  </nav>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["startSetNavToggled"])
  }
};
</script>

<style scoped lang="scss">
nav {
  @include grid($cols: 1fr, $just: center);
  font-weight: bold;
  background: linear-gradient(to right, #fff, $black, #fff);
  padding: 2px 0;
  a {
    text-decoration: none;
    font-size: $text-lg;
    padding: 30px 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
    margin: 2px 0;
    color: $green;
  }
}
.router-link-exact-active {
  background: linear-gradient(to right, #fff, $pistachio, #fff);
  color: $pine;
}
@media (min-width: $md) {
  nav {
    @include flex($just: space-around, $align: center);
    background: transparent;
    a {
      padding: 5px 15px;
      margin: 0px 5px;
      background-color: transparent;
      font-size: $text-md;
      width: fit-content;
      margin: 5px 0;
    }
  }
  .router-link-exact-active {
    background: none;
  }
}
</style>