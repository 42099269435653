<template>
  <section class="banner">
    <section class="container">
      <h1 class="text__center text__pistachio">Colossal Quality, Puny Prices</h1>
      <p class="padding__sm text__md text__center">
        Printing in-house means higher quality control and lower prices.
        <br />Select a product below to review pricing.
      </p>
      <Select :products="products" @setActive="emitSetActive" />
      <section class="margin__md banner-request">
        <p
          class="text__center"
        >If you have an idea for a special project not covered above, please submit a marketing request to get started on designing and printing your custom print material.</p>
        <div class="flex__center">
          <a
            href="http://marketing.fbhl.com"
            name="Make a marketing request"
            target="_self"
            rel="noreferrer"
            class="btn btn__orange btn__sm mt-15"
          >Marketing Request</a>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import Select from "./Select";
export default {
  props: ["products"],
  components: {
    Select
  },
  methods: {
    emitSetActive(val) {
      this.$emit("setActive", val);
    }
  }
};
</script>

<style scoped lang="scss">
.banner {
  background-color: $green;
  padding: 50px 0;
}
.banner-request {
  display: none;
  background-color: $pistachio;
  border-radius: 5px;
  padding: 25px;
}
@media (min-width: $md) {
  .banner-request {
    display: block;
  }
}
</style>