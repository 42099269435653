
const state = {
   products: [
      {
         name: "Booklets",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/booklet1.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/booklet2.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/booklet3.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/booklet4.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/booklet5.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/booklet6.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/booklet7.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/booklet-1-inside.png",
            "https://cdn.fbhl.com/Print-Center/examples/booklet-1-outside.png"
         ],
         templates: [

         ],
         link: "booklets",
         description:
            "Provide your clients with a breakdown of information you want them to know. ",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "4 Page Booklets",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["4 Page", "$0.19", 1],
               ],
               footer: [
                  "Note:",
                  "When co-branding, you will split the cost between you and your partner. \nIf a realtor wants a custom item, the cost may vary due to operational fees. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
                 
               ]
            },
            {
               title: "4 Page Booklets \n(Common Quantities)",
               headings: [
                  "Type",
                  "Qty",
                  "Cost"
               ],
               rows: [
                  ["4 Page", "50", "$9.50"],
                  ["4 Page", "25", "$4.75"],
                  ["4 Page", "10", "$1.90"],
               ]
            }, 
            {
               title: "8 Page Booklets",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["8 Page", "$0.38", 1],
               ],
               footer: [
                  "Note:",
                  "When co-branding, you will split the cost between you and your partner. \nIf a realtor wants a custom item, the cost may vary due to operational fees. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "8 Page Booklets \n(Common Quantities)",
               headings: [
                  "Type",
                  "Qty",
                  "Cost"
               ],
               
               rows: [                 
                  ["8 Page", "50", "19.00"],
                  ["8 Page", "25", "$9.50"],
                  ["8 Page", "10", "$3.80"],
               ]
            }, 
            {
               title: "12 Page Booklets ",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["12 Page", "$0.57", 1],

               ],
               footer: [
                  "Note:",
                  "When co-branding, you will split the cost between you and your partner. \nIf a realtor wants a custom item, the cost may vary due to operational fees. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "12 Page Booklets \n(Common Quantities)",
               headings: [
                  "Type",
                  "Qty",
                  "Cost"
               ],
               rows: [

                  ["12 Page", "50", "$28.50"],
                  ["12 Page", "25", "$14.25"],
                  ["12 Page", "10", "$5.70"],
               ]
            },  
            {
               title: "16 Page Booklets",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["16 Page", "$.76", 1],
               ],
               footer: [
                  "Note:",
                  "When co-branding, you will split the cost between you and your partner. \nIf a realtor wants a custom item, the cost may vary due to operational fees. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "16 Page Booklets \n(Common Quantities)",
               headings: [
                  "Type",
                  "Qty",
                  "Cost"
               ],
               rows: [
                  ["16 Page", "50", "38.00"],
                  ["16 Page", "25", "$19.00"],
                  ["16 Page", "10", "$7.60"],
               ]
            }, 
            {
               title: "20 Page Booklets",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["20 Page", "$.95", 1],
               ],
               footer: [
                  "Note:",
                  "When co-branding, you will split the cost between you and your partner. \nIf a realtor wants a custom item, the cost may vary due to operational fees. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "20 Page Booklets \n(Common Quantities)",
               headings: [
                  "Type",
                  "Qty",
                  "Cost"
               ],
               rows: [

                  ["20 Page", "50", "47.50"],
                  ["20 Page", "25", "$23.75"],
                  ["20 Page", "10", "$9.50"]
               ]
            }, 
            
         ]
      },
      
      {
         name: "Business Cards",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/business-card-1-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/business-card-1-back.png",
            "https://cdn.fbhl.com/Print-Center/examples/business-card-2-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/business-card-2-back.png",
            "https://cdn.fbhl.com/Print-Center/examples/business-card-3-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/business-card-3-back.png",
            "https://cdn.fbhl.com/Print-Center/examples/business-card-4-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/business-card-4-back.png",
         ],
         templates: [
            "https://cdn.fbhl.com/Print-Center/template/business-card-1.png"
         ],
         link: "business-cards",
         description:
            "Choose from several of our designs to create a Flat Branch business card that works for you.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Business Cards",
               subheading: "Free for Flat Branch Sales Employees",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["One Sided", "$0.02", 24],
                  ["Double Sided", "$0.03", 24]
               ],
               footer: [
                  "Note:",
                  "When co-branding, you will split the cost between you and your partner. \nIf a realtor wants a custom item, the cost may vary due to operational fees. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Business Cards \n(Common Quantities)",
               subheading: "Free for Flat Branch Sales Employees",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["One Sided", "1000", "$20.16"],
                  ["One Sided", "500", "$10.08"],
                  ["One Sided", "250", "$5.28"],
                  ["Double Sided", "1000", "$30.24"],
                  ["Double Sided", "500", "$15.12"],
                  ["Double Sided", "250", "$7.92"]
               ]
            }
         ]
      },
      {
         name: "Calendars",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/cal1.png",
            "https://cdn.fbhl.com/Print-Center/examples/cal3.png",
            "https://cdn.fbhl.com/Print-Center/examples/cal4.png",
            "https://cdn.fbhl.com/Print-Center/examples/cal6.png"
         ],
         templates: [
            "https://cdn.fbhl.com/Print-Center/template/calendar-template1.jpg",
            "https://cdn.fbhl.com/Print-Center/template/calendar-template2.jpg",
            "https://cdn.fbhl.com/Print-Center/template/calendar-template3.jpg"
         ],
         link: "calendars",
         description:
            "Help your clients plan their year and keep you top of mind.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Calendars",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Calendars", "$1.33", 1],
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Calendars \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Standard", "500", "$875.00"],
                  ["Standard", "250", "$332.50"],
                  ["Standard", "100", "$133.00"],
                  ["Standard", "50", "$66.50"],
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            }
         ]
      }, 
           {
         name: "Door Hangers",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/door-hanger-1.png",
            "https://cdn.fbhl.com/Print-Center/examples/door-hanger-2.png"
         ],
         templates: [
            "https://cdn.fbhl.com/Print-Center/template/door-hanger-1.png"
         ],
         link: "door-hangers",
         description:
            "Provide your clients with a breakdown of information you want them to know.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Door Hangers",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Standard", "$0.40", 4]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Door Hangers \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Standard", "500", "$200.00"],
                  ["Standard", "250", "$100.00"],
                  ["Standard", "100", "$40.00"]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            }
         ]
      },
      {
         name: "Envelopes",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/envelope-1.png",
            "https://cdn.fbhl.com/Print-Center/examples/envelope-2.png"
         ],
         templates: [
         ],
         link: "envelopes",
         description:
            "Provide your clients with a breakdown of information you want them to know.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Standard Envelopes",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Standard", "$0.09", 250]
               ],
               footer: [
                  "Note:",
                  "Standard Envelope sizes includes: A2, #10, and #10 with window. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Standard Envelopes \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Standard", "1000", "$90.00"],
                  ["Standard", "500", "$45.00"],
                  ["Standard", "250", "$22.50"]
               ], 
               footer: [
                  "Note:",
                  "Standard Envelope sizes includes: A2, #10, and #10 with window. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Specialty Envelopes",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Specialty", "$0.12", 250]
               ],
               footer: [
                  "Note:",
                  "Specialty Envelope sizes includes: A6, A7, and 6x9. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Specialty Envelopes \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Specialty", "1000", "$120.00"],
                  ["Specialty", "500", "$60.00"],
                  ["Specialty", "250", "$30.00"]
               ],
               footer: [
                  "Note:",
                  "Specialty Envelope sizes includes: A6, A7, and 6x9. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            }
         ]
      },
      {
         name: "Flyers",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/flyer-credit-score.png",
            "https://cdn.fbhl.com/Print-Center/examples/flyer-fha.png",
            "https://cdn.fbhl.com/Print-Center/examples/flyer-homebuying.png"
         ],
         templates: [
            "https://cdn.fbhl.com/Print-Center/template/flyer-1.png"
         ],
         link: "flyers",
         icon: "",
         description:
            "Flyers for everything you need. Advertise with your realtor parter. Drum up business for a local event, etc.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Flyers: Single-Sided",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Single-Sided", "$0.07", 2]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Flyers: Double-Sided",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Double-Sided", "$0.10", 2]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Flyers: Single-Sided \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Single-Sided", "1000", "$70.00"],
                  ["Single-Sided", "500", "$35.00"],
                  ["Single-Sided", "100", "$7.00"]
               ],
               footer: [
                  "Note:",
                  "co-brand price difference due to Respa requirements. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Flyers: Double-Sided \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Double-Sided", "1000", "$100.00"],
                  ["Double-Sided", "500", "$50.00"],
                  ["Double-Sided", "100", "$10.00"]
               ],
               footer: [
                  "Note:",
                  "co-brand price difference due to Respa requirements. \n All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            }
         ]
      },
      {
         name: "Magnets",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/magnetic-postcard-1.png",
            "https://cdn.fbhl.com/Print-Center/examples/magnet-1.png",
            "https://cdn.fbhl.com/Print-Center/examples/magnet-2.png"
         ],
         templates: [],
         link: "magnets",
         description:
            "Provide your clients with a breakdown of information you want them to know.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Magnets",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Magnetic Business Cards", "$0.09", 24],
                  ["4x6 Magnetic Postcards", "$0.49", 4],
                  ["Sports Calendar Magnets", "$0.49", 4],
                  ["8.5x11 Magnetic Calendars", "0.97", 2]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Magnets \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Magnetic Business Cards", "500", "$45.00"],
                  ["Magnetic Business Cards", "250", "$22.50"],
                  ["4x6 Magnetic Postcards", "100", "$49.00"],
                  ["4x6 Magnetic Postcards", "50", "$24.50"],
                  ["Sports Calendar Magnets", "100", "$49.00"],
                  ["Sports Calendar Magnets", "50", "$24.50"],
                  ["8.5x11 Magnetic Calendars", "100", "$97.00"],                
                  ["8.5x11 Magnetic Calendars", "50", "$48.50"]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            }
         ]
      },
      {
         name: "Note Cards",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/thank-you-card-1.png",
            "https://fbhl-web-photos.s3.amazonaws.com/Print-Center/examples/thank-you-card-2.PNG",
            "https://cdn.fbhl.com/Print-Center/examples/thank-you-card-3.png",
            "https://fbhl-web-photos.s3.amazonaws.com/Print-Center/examples/thank-you-card-4.PNG"
         ],
         templates: [
            "https://fbhl-web-photos.s3.amazonaws.com/Print-Center/template/thank-you-card-1.png",
            "https://fbhl-web-photos.s3.amazonaws.com/Print-Center/template/thank-you-card-2.png"
         ],
         link: "note-cards",
         description:
            "Provide your clients with a breakdown of information you want them to know.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Note Cards: Single Sided",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Cards Only", "$0.10", 4],
                  ["Cards w/ Envelopes", "$0.19", 125]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Note Cards: Double Sided",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Cards Only", "$0.11", 4],
                  ["Cards w/ Envelopes", "$0.20", 125]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Note Cards: Single Sided \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Cards Only", "500", "$50.00"],
                  ["Cards Only", "250", "$25.20"],
                  ["Cards Only", "100", "$10.00"],
                  ["Cards w/ Envelopes", "1000", "$180.00"],
                  ["Cards w/ Envelopes", "500", "$95.00"],
                  ["Cards w/ Envelopes", "250", "$47.50"]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Note Cards: Double Sided \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Cards Only", "500", "$55.00"],
                  ["Cards Only", "250", "$27.72"],
                  ["Cards Only", "100", "$11.00"],
                  ["Cards w/ Envelopes", "1000", "$190.00"],
                  ["Cards w/ Envelopes", "500", "$100.00"],
                  ["Cards w/ Envelopes", "250", "$50.00"]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            }
         ]
      },
      {
         name: "Planners",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/planner.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/planner-2.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/planner-3.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/planner-4.jpg", 
            "https://cdn.fbhl.com/Print-Center/examples/planner4.jpg"
         ],
         templates: [
            "https://cdn.fbhl.com/Print-Center/template/planner-template.jpg",
            "https://cdn.fbhl.com/Print-Center/template/planner-template2.jpg",
            "https://cdn.fbhl.com/Print-Center/template/planner-template3.jpg"
         ],
         link: "planners",
         description:
            "Help your clients stay organized and keep you top of mind.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Planners",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Planners", "$1.41", 1]
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            },
            {
               title: "Planners \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Standard", "500", "$705.00"],
                  ["Standard", "250", "$352.50"],
                  ["Standard", "100", "$141.00"],
                  ["Standard", "50", "$70.50"],
               ],
               footer: [
                  "Note:",
                   "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."
               ]
            }
         ]
      },
      
      {
         name: "Postcards",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/postcard-1-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/postcard-1-back.png",
            "https://cdn.fbhl.com/Print-Center/examples/postcard-2-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/postcard-2-back.png",
            "https://cdn.fbhl.com/Print-Center/examples/postcard-3-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/postcard-3-back.png",
            "https://cdn.fbhl.com/Print-Center/examples/postcard-4-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/postcard-4-back.png",
            "https://cdn.fbhl.com/Print-Center/examples/postcard-5-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/postcard-5-back.png"
         ],
         templates: [
            "https://cdn.fbhl.com/Print-Center/template/4x6-postcard-1.png",
            "https://cdn.fbhl.com/Print-Center/template/4x6-postcard-2.png",
            "https://cdn.fbhl.com/Print-Center/template/5x7-postcard-1.png",
            "https://cdn.fbhl.com/Print-Center/template/5x7-postcard-2.png",
            "https://cdn.fbhl.com/Print-Center/template/eddm-1.png",
            "https://cdn.fbhl.com/Print-Center/template/eddm-2.png"
         ],
         link: "postcards",
         description:
            "Choose from single or double sided. Multiple sizes to choose from, some offering EDDM capabilities for even more convenience. Co-brand with your realtor partners to reach out to hundreds of potential borrowers.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Postcards: Single Sided",
               headings: ["Size", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["4x6", "$0.05", "9"],
                  ["5x7", "$0.10", "4"],
                  ["6x11-EDDM", "$0.14", "3"],
               ],
               footer: ["Mailing Costs:", "Postcards $0.60 per unit \nEDDM $0.25 per unit", "Note:", "All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."]
            },
            {
               title: "Postcards: Double Sided",
               headings: ["Size", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["4x6", "$0.06", "9"],
                  ["5x7", "$0.11", "4"],
                  ["6x11-EDDM", "$0.16", "3"],
               ],
               footer: ["Mailing Costs:", "Postcards $0.60 per unit \nEDDM $0.25 per unit.","Note:","All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."]
            },
            {
               title: "Postcards: Single Sided \n(Common Quantities)",
               headings: ["Size", "Qty", "Cost"],
               rows: [
                  ["4x6", "1000", "$50.40"],
                  ["4x6", "500", "$25.20"],
                  ["4x6", "100", "$5.40"],
                  ["5x7", "1000", "$100.00"],
                  ["5x7", "500", "$50.00"],
                  ["5x7", "100", "$10.00"],
                  ["6x11-EDDM", "1000", "$140.28"],
                  ["6x11-EDDM", "500", "$70.14"],
                  ["6x11-EDDM", "100", "$14.28"]
               ],
               footer: ["Mailing Costs:", "Postcards $0.60 per unit \nEDDM $0.25 per unit.", "Note:","All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."]
            },
            {
               title: "Postcards: Double Sided \n(Common Quantities)",
               headings: ["Size", "Qty", "Cost"],
               rows: [
                  ["4x6", "1000", "$60.48"],
                  ["4x6", "500", "$30.24"],
                  ["4x6", "100", "$6.48"],

                  ["5x7", "1000", "$110.00"],
                  ["5x7", "500", "$55.00"],
                  ["5x7", "100", "$11.00"],

                  ["6x11-EDDM", "1000", "$160.32"],
                  ["6x11-EDDM", "500", "$80.16"],
                  ["6x11-EDDM", "100", "$16.32"]
               ],
               footer: ["Mailing Costs:", "Postcards $0.60 per unit \nEDDM $0.25 per unit", "Note:","All orders will incur a $7.50 handling fee charge on the final invoice to cover labor and equipment costs."]
            }
         ]
      },
      {
         name: "Return Address Labels",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/rl-custom1.jpg",
            "https://cdn.fbhl.com/Print-Center/examples/rl-standard.png"
         ],
         templates: [
            "https://cdn.fbhl.com/Print-Center/template/regular-return-label-template.jpg",
            "https://cdn.fbhl.com/Print-Center/template/special-return-labels-template.jpg"
         ],
         link: "labels",
         description:
            "Easily label your mailers and keep your information consistent for easy access to your clients.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Standard Return Address Labels",
               headings: ["Size", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Standard", "$0.02", "30"],

               ],
               footer: ["Note:", "All orders will incur a $7.50 handling fee cvharge on the final invouce to cover labor and equipment costs."]
            },
            {
               title: "Custom Return Address Labels",
               headings: ["Size", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Custom", "$0.02", "21"],
               ],
               footer: ["Note:", "All orders will incur a $7.50 handling fee cvharge on the final invouce to cover labor and equipment costs."]
            },
            {
               title: "Standard Return Address Labels \n(Common Quantities)",
               headings: ["Size", "Qty", "Cost"],
               rows: [
                  ["Standard", "500", "$10.20"],
                  ["Standard", "250", "$5.40"],
                  ["Standard", "100", "$2.40"]
               ],
               footer: ["Note:", "All orders will incur a $7.50 handling fee cvharge on the final invouce to cover labor and equipment costs."]
            },
            {
               title: "Custom Return Address Labels \n(Common Quantities)",
               headings: ["Size", "Qty", "Cost"],
               rows: [
                  ["Custom", "500", "$10.08"],
                  ["Custom", "250", "$5.04"],
                  ["Custom", "100", "$2.10"]
               ],
               footer: ["Note:", "All orders will incur a $7.50 handling fee cvharge on the final invouce to cover labor and equipment costs."]
            }
         ]
      },
      
      {
         name: "Stickers",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/sticker-1.png",
            "https://cdn.fbhl.com/Print-Center/examples/square-stickers.png",
            "https://cdn.fbhl.com/Print-Center/examples/sticker-business-card-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/sticker-business-card-back.png"
         ],
         templates: [
            "https://cdn.fbhl.com/Print-Center/template/2x2-sticker-circle-1.png",
            "https://cdn.fbhl.com/Print-Center/template/3x3-sticker-circle-1.png",
            "https://cdn.fbhl.com/Print-Center/template/business-card-stickers.png"
         ],
         link: "stickers",
         description:
            "Provide your clients with a breakdown of information you want them to know.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Stickers",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["2\" Circle Stickers", "$0.03", 20],
                  ["3\" Circle Stickers", "$0.06", 15],
                  ["3 1/3\" x 4\" Square Stickers", "$0.07", 12],
                  ["Business Card Stickers", "$0.09", 20]
               ],
               footer: ["Note:", "All orders will incur a $7.50 handling fee cvharge on the final invouce to cover labor and equipment costs."]
            },
            {
               title: "Stickers \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["2\" Circle Stickers", "500", "$15.00"],
                  ["2\" Circle Stickers", "250", "$7.80"],
                  ["2\" Circle Stickers", "100", "$3.00"],
                  ["3\" Circle Stickers", "500", "$30.60"],
                  ["3\" Circle Stickers", "250", "$15.30"],
                  ["3\" Circle Stickers", "100", "$6.30"],
                  ["3 1/3\" x 4\" Square Stickers", "500", "$35.28"],
                  ["3 1/3\" x 4\" Square Stickers", "250", "$17.64"],
                  ["3 1/3\" x 4\" Square Stickers", "100", "$7.56"],
                  ["Business Card Stickers", "500", "$46.00"],
                  ["Business Card Stickers", "250", "$23.92"],
                  ["Business Card Stickers", "100", "$9.20"]
               ],
               footer: ["Note:", "All orders will incur a $7.50 handling fee cvharge on the final invouce to cover labor and equipment costs."]
            }
         ]
      },
      {
         name: "Trifolds",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/trifold-1-front.png",
            "https://cdn.fbhl.com/Print-Center/examples/trifold-1-back.png",
         ],
         templates: [
            "https://cdn.fbhl.com/Print-Center/template/trifold-1.png",
            "https://cdn.fbhl.com/Print-Center/template/trifold-2.png"
         ],
         link: "trifolds",
         description:
            "Provide your clients with a breakdown of information you want them to know.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Trifolds",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["One Sided", "$0.10", 2]
               ],
               footer: ["Note:", "All orders will incur a $7.50 handling fee cvharge on the final invouce to cover labor and equipment costs."]
            },
            {
               title: "Trifolds \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Standard", "500", "$50.00"],
                  ["Standard", "250", "$25.00"],
                  ["Standard", "100", "$10.00"]
               ],
               footer: ["Note:", "All orders will incur a $7.50 handling fee cvharge on the final invouce to cover labor and equipment costs."]
            }
         ]
      },
      
      {
         name: "Water Bottle Labels",
         photos: [
            "https://cdn.fbhl.com/Print-Center/examples/water-bottle-label-1.png",
            "https://cdn.fbhl.com/Print-Center/examples/water-bottle-label-2.png"
         ],
         templates: [
            "https://cdn.fbhl.com/Print-Center/template/water-bottle-labels-1.png"
         ],
         link: "water-bottle-labels",
         description:
            "Provide your clients with a breakdown of information you want them to know.",
         rules: "Please use the printing template(s) below as guidance for how to design for print.",
         pricingNote: "**Shipping and handling fees may apply and will be added to the below prices",
         prices: [
            {
               title: "Water Bottle Labels",
               headings: ["Type", "Price Per Unit", "Qty Per Page"],
               rows: [
                  ["Standard", "$0.05", 10]
               ],
               footer: ["Note:", "All orders will incur a $7.50 handling fee cvharge on the final invouce to cover labor and equipment costs."]
            },
            {
               title: "Water Bottle Labels \n(Common Quantities)",
               headings: ["Type", "Qty", "Cost"],
               rows: [
                  ["Any Size", "1000", "$50.00"],
                  ["Any Size", "500", "$25.00"],
                  ["Any Size", "100", "$5.00"]
               ],
               footer: ["Note:", "All orders will incur a $7.50 handling fee cvharge on the final invouce to cover labor and equipment costs."]
            }
         ]
      }
   ],
   activeProduct: null
}

const getters = {
   getProducts(state) {
      return state.products;
   },
   getActiveProduct(state) {
      if (!state.activeProduct) return null;
      const found = state.products.filter(product => {
         return product.link === state.activeProduct;
      });
      if (found.length) return found[0];
      return null;
   }
}

const actions = {
   startSetActiveProduct({ commit }, link) {
      commit("SET_ACTIVE_PRODUCT", link);
   }
}

const mutations = {
   SET_ACTIVE_PRODUCT(state, link) {
      state.activeProduct = link;
   }
}

export default {
   state,
   getters,
   actions,
   mutations
}