<template>
  <section class="tiles padding__sm">
    <router-link
      v-for="product in getProducts"
      :key="product.link"
      :to="`/products/${product.link}`"
      :name="`${product.name} page`"
      class="tile flex__center"
      :style="`background: linear-gradient(to bottom right, rgba(159, 212, 172, 0.85), rgba(159, 212, 172, 0.65)), url('${product.photos[0]}');`"
    >
      <p class="text__pine">{{ product.name }}</p>
    </router-link>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getProducts"])
  }
};
</script>

<style scoped lang="scss">
.tiles {
  @include grid($cols: 1fr, $row-gap: 50px, $col-gap: 50px, $just: center);
}
.tile {
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  padding: 50px;
  border-radius: 25px;
  width: 300px;
  height: 300px;
  text-decoration: none;
  p {
    font-weight: bold;
    font-size: $text-lg;
  }
}
@media (min-width: $md) {
  .tiles {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $xl) {
  .tiles {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>