<template>
  <section class="container">
    <router-link to="/products" class="link__none flex__left padding__sm">
      <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Products
    </router-link>
    <section class="product__grid margin__md">
      <Slider :product="getActiveProduct" />
      <Content :product="getActiveProduct" />
    </section>
    <h2 class="text__center mt-15">Choose Your Destination</h2>
    <div class="product-process">
      <div class="product-process--tile">
        <h3 class="text__center">I Need Help.</h3>
        <p class="text__center">Use this form if you need print material designed for you.</p>
        <a
          href="http://marketing.fbhl.com"
          name="Make a marketing request"
          target="_self"
          rel="noreferrer"
          class="btn btn__orange btn__sm"
        >Marketing Request</a>
      </div>
      <div class="product-process--tile">
        <h3 class="text__center">Let's Print!</h3>
        <p class="text__center">Follow the link below to request your print material</p>
        <a href="/print-job" name="Print form" class="btn btn__sm btn__yellow">Get Started</a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Slider from "./Slider";
import Content from "./Content";
// import Form from "./Form";
export default {
  components: {
    Slider,
    Content
    // Form
  },
  metaInfo: {
    title: "Product | Printzilla"
  },
  computed: {
    ...mapGetters(["getActiveProduct"])
  },
  methods: {
    ...mapActions(["startSetActiveProduct"])
  },
  created() {
    const product = this.$route.params.product;
    this.startSetActiveProduct(product);
  }
};
</script>

<style scoped lang="scss">
.product__grid {
  @include grid($cols: 100%, $row-gap: 50px, $col-gap: 50px);
}
.product-process {
  @include flex($just: center, $align: center, $wrap: wrap);
  margin: 25px 0;
}
.product-process--tile {
  @include grid($cols: 1fr, $row-gap: 5px, $just: center, $align: center);
  padding: 15px;
  background-color: $pistachio;
  border-radius: 5px;
  margin: 15px;
  height: 250px;
  width: 275px;
}
</style>