<template>
  <section class="container">
    <section class="products-heading padding__md">
      <h1 class="text__pine mr-10">What We Offer</h1>
      <router-link to="/print-job" class="btn btn__orange">Get Started</router-link>
    </section>
    <section class="products-product--grid">
      <Product v-for="product in getProducts" :key="product.link" :product="product" />
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Product from "./Product";
export default {
  components: {
    Product
  },
  metaInfo: {
    title: "View Our Products | Printzilla"
  },
  computed: {
    ...mapGetters(["getProducts"])
  }
};
</script>

<style scoped lang="scss">
.products-heading {
  @include flex($just: space-between, $align: center, $wrap: wrap);
}
h1 {
  padding-bottom: 25px;
}
.products-product--grid {
  @include grid($cols: 100%, $row-gap: 20px, $col-gap: 20px);
  margin-bottom: 50px;
}
@media (min-width: $md) {
  h1 {
    padding-bottom: 0;
  }
  .products-product--grid {
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  }
}
@media (min-width: $xl) {
  .products-product--grid {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  }
}
</style>