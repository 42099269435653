<template>
  <section class="product-content">
    <h1 class="text__pine">{{ product.name }}</h1>
    <section class="product-content__grid">
      <section class="product-content--desc">
        <h3>About This Product</h3>
        <p>{{ product.description }}</p>
      </section>
      <section class="product-content--rules">
        <h3>Printing Guidelines</h3>
        <p>{{ product.rules }}</p>
      </section>
    </section>
    <section v-if="product.templates && product.templates.length > 0">
      <Templates :product="product" />
    </section>
    <div>
      <h3>Pricing Structure</h3>
      <p class="text__bold mt-15">{{ product.pricingNote }}</p>
    </div>
    <section class="product-content--pricing">
      <Pricing v-for="pricing in product.prices" :key="pricing.title" :pricing="pricing" />
    </section>
  </section>
</template>

<script>
import Pricing from "./Pricing";
import Templates from "./Templates";
export default {
  props: ["product"],
  components: {
    Pricing,
    Templates
  }
};
</script>

<style scoped lang="scss">
.product-content {
  @include grid($cols: 100%, $row-gap: 50px);
}
.product-content__grid {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 50px);
}
.product-content--pricing {
  @include grid($cols: 1fr, $row-gap: 50px, $col-gap: 25px, $align: top);
}
.product-content--desc,
.product-content--rules {
  @include grid($cols: 1fr, $rows: 28px 1fr, $row-gap: 20px, $col-gap: 25px);
}
.product-content--pricing {
  margin-bottom: 50px;
}
@media (min-width: $lg) {
  .product-content {
    grid-row-gap: 50px;
  }
  .product-content__grid {
    grid-template-columns: 1fr 1fr;
  }
  .product-content--pricing {
    grid-template-columns: 1fr 1fr;
  }
}
</style>