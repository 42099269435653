<template>
  <div class="nav-mobile">
    <div @click="toggleNav" class="icon">
      <font-awesome-icon :icon="icon" size="3x" class="ml-10" />
    </div>
    <transition name="slide">
      <section v-if="getNavToggled" class="modal">
        <section class="nav-wrapper">
          <slot></slot>
        </section>
      </section>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["getNavToggled"]),
    icon() {
      return this.getNavToggled ? "times" : "bars";
    }
  },
  methods: {
    ...mapActions(["startSetNavToggled"]),
    toggleNav() {
      this.startSetNavToggled(!this.getNavToggled);
    }
  }
};
</script>

<style scoped lang="scss">
.icon {
  color: $pine;
}
.modal {
  top: $header-height;
  height: calc(100vh - #{$header-height});
}
.nav-wrapper {
  padding: 10px 0;
  height: 100%;
  width: 90%;
  min-width: 300px;
  max-width: 400px;
  margin-left: auto;
}
.nav-wrapper {
  background-color: #fff;
}
.slide-enter {
  transform: translateX(100%);
}
.slide-enter-to {
  transform: translateX(0%);
  transition: transform 0.35s ease-in-out;
}
.slide-leave {
  transform: translateX(0%);
}
.slide-leave-to {
  transform: translateX(100%);
  transition: transform 0.35s ease-in-out;
}
</style>