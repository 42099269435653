import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);

//routes
import Home from "../components/Pages/Home";
import Print from "../components/Pages/Print";
import Pricing from "../components/Pages/Pricing";
import PricingNew from "../components/Pages/PricingNew";
import Products from "../components/Pages/Products";
import Product from "../components/Pages/Product";

export default new VueRouter({
   mode: "history",
   scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
         return savedPosition;
      }
      return {
         x: 0,
         y: 0,
         behavior: 'smooth'
      }
   },
   routes: [
      {
         path: "/",
         name: "home",
         component: Home
      },
      {
         path: "/print-job",
         name: "print-job",
         component: Print
      },
      {
         path: "/pricing",
         name: "pricing",
         component: PricingNew
      },
      {
         path: "/pricing-new",
         name: "pricing-new",
         component: Pricing
      },
      {
         path: "/products",
         name: "products",
         component: Products
      },
      {
         path: "/products/:product",
         name: "products-product",
         component: Product
      },
      {
         path: "*",
         name: "not-found",
         beforeEnter: (to, from, next) => {
            return next("/");
         }
      }
   ]
});