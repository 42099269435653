<template>
  <section class="modal modal--zoom" @click.self="emitExitModal">
    <div class="modal--zoom-exit flex__center" @click="emitExitModal">
      <font-awesome-icon icon="times" size="2x" class="exit" />
    </div>
    <VueSlickCarousel v-bind="settings">
      <div class="carousel-slide" v-for="link in imgArray" :key="link">
        <div class="carousel-slide--content" :style="`background-image: url('${link}');`"></div>
      </div>
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  props: ["active", "product"],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 5,
        initialSlide: 0,
        focusOnSelect: false
      }
    };
  },
  methods: {
    emitExitModal() {
      this.$emit("exitModal");
    }
  },
  computed: {
    imgArray() {
      const copied = JSON.parse(JSON.stringify(this.product.photos));
      let display = copied.filter(img => img !== this.active);
      return [this.active, ...display];
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  padding: 50px;
}
.modal--zoom-exit {
  @include pos-abs($top: 25px, $left: null, $right: 25px);
  background-color: $powder;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  color: $pine;
  cursor: pointer;
}
.carousel-slide {
  height: calc(100vh - 125px);
  width: calc(100vw - 150px);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.carousel-slide--content {
  height: calc(100vh - 125px);
  width: calc(100vw - 150px);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
img {
  height: 100%;
  background-color: #fff;
  margin: auto;
}
button.slick-prev:before,
button.slick-next:before {
  color: $powder !important;
  font-size: 2rem !important;
}
</style>