<template>
  <section>
    <section class="banner">
      <div class="container">
        <h1 class="text__center padding__sm">New Print Job</h1>
        <p
          class="text__center text__md"
        >Fill out one of the forms below to get started on your project.</p>
      </div>
    </section>
    <div class="container" style="height: 1000px;">
      <section v-if="!isDesigned" class="print-form--container">
        <h2 class="text__center mt-15">Choose Your Destination</h2>
        <div class="print-form--confirm">
          <div class="confirm-tile">
            <h3 class="text__center">I Need Help.</h3>
            <p class="text__center">For assistance, reach out to <a href="mailto:marketingsupport@fbhl.com">marketingsupport@fbhl.com</a></p>
            <!-- <a
              href="http://marketing.fbhl.com"
              name="Make a marketing request"
              target="_self"
              rel="noreferrer"
              class="btn btn__orange btn__sm"
            >Marketing Request</a> -->
          </div>
          <div class="confirm-tile">
            <h3 class="text__center">Let's Print!</h3>
            <p class="text__center">Use this form if you have a design ready that you need printed.</p>
            <button class="btn btn__yellow btn__sm" @click="toggleIsDesigned">I'm Ready</button>
          </div>
        </div>
      </section>
      <section v-else>
        <!-- <PrintForm class="print-form--container" /> -->
        <iframe 
      id="JotFormIFrame-242403185331043"
      title="Printzilla Requests"
      src="https://form.jotform.com/242403185331043"
      frameborder="0"
      style="min-width:100%;max-width:100%;height:1000px;border:none;"
      scrolling="no"
    ></iframe>
    <script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>
      </section>
    </div>
  </section>
</template>

<script>
//import PrintForm from "./PrintForm";
export default {
  // components: {
  //   PrintForm
  // },
  metaInfo: {
    title: "Request a Print | Printzilla"
  },
  data() {
    return {
      isDesigned: false
    };
  },
  methods: {
    toggleIsDesigned() {
      this.isDesigned = true;
    }
  }
};
</script>

<style scoped lang="scss">
.banner {
  background: linear-gradient(rgba(38, 72, 34, 0.7), rgba(38, 72, 34, 0.7)),
    url("https://cdn.fbhl.com/Print-Center/assets/print-center-lazer-min.jpeg");
  padding: 100px 0 400px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  * {
    color: $powder;
  }
}
.print-form--container {
  margin-top: -250px;
  margin-bottom: 200px;
  padding: 25px 5px;
  background-color: #fff;
  border-radius: 8px;
  @include shadow();
  @include grid($cols: 1fr, $row-gap: 50px);
}
.print-form--confirm {
  @include flex($just: center, $align: center, $wrap: wrap);
  margin: 25px 0;
}
.confirm-tile {
  @include grid($cols: 1fr, $row-gap: 5px, $just: center, $align: center);
  padding: 15px;
  background-color: $pistachio;
  border-radius: 5px;
  margin: 15px;
  height: 250px;
  width: 275px;
}
</style>