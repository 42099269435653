<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["startSetNavToggled"])
  },
  mounted() {
    this.startSetNavToggled(false);
  }
};
</script>

<style scoped lang="scss">
</style>