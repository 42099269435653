<template>
  <header>
    <div class="container">
      <div class="flex__between">
        <router-link to="/" class="link__none">
          <img
            src="https://cdn.fbhl.com/Print-Center/logos/Printzilla-Logo-horo-2-color-for-web.png"
            name="Printzilla logo"
            alt="Printzilla"
            width="200px"
            height="75px"
            class="logo"
          />
        </router-link>
        <Mobile class="mobile">
          <NavList />
        </Mobile>
        <Desktop class="desktop">
          <NavList />
        </Desktop>
      </div>
    </div>
  </header>
</template>

<script>
import NavList from "./navigation/NavList";
import Mobile from "./navigation/Mobile";
import Desktop from "./navigation/Desktop";
export default {
  components: {
    NavList,
    Mobile,
    Desktop
  }
};
</script>

<style scoped lang="scss">
header {
  background-color: #fff;
  position: sticky;
  top: 0;
  @include shadow;
  height: $header-height;
  z-index: 10;
}
.desktop {
  display: none;
}
.logo {
  margin: 10px 0;
}
@media (min-width: $md) {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}
</style>