import Vue from "vue";
import Vuex from "vuex";

import site from "./modules/site";
import products from "./modules/products";

Vue.use(Vuex);

export default new Vuex.Store({
   modules: {
      site,
      products
   }
})