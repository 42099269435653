<template>
  <table class="pricing-table product__pricing-table">
    <thead>
      <tr class="table-title">
        <td :colspan="pricing.headings.length" class="pre-wrap">
          {{ pricing.title }}
          <br />
          <span v-if="pricing.subheading" class="text__sm">{{ pricing.subheading }}</span>
        </td>
      </tr>
      <tr class="table-heading">
        <td v-for="heading in pricing.headings" :key="heading">{{ heading }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in pricing.rows" :key="index" class="table-row">
        <td v-for="val in row" :key="`${val}-${index}`">{{ val }}</td>
      </tr>
    </tbody>
    <tfoot v-if="pricing.footer">
      <tr class="note">
        <td :colspan="pricing.headings.length">{{ pricing.footer[0] }}</td>
      </tr>
      <tr class="note note__sm">
        <td :colspan="pricing.headings.length" class="pre-wrap">{{ pricing.footer[1] }}</td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  props: ["pricing"]
};
</script>

<style scoped lang="scss">
@media (min-width: $lg) {
  .product__pricing-table {
    margin: 0 auto;
  }
}
</style>