<template>
  <section>
    <section class="banner">
      <section class="container">
        <h1 class="text__center">Colossal Quality, Puny Prices</h1>
        <p class="text__md text__center padding__sm">
          Printing in-house means higher quality control and lower prices.
          <br />Select a product below to review pricing.
        </p>
        <section class="product-tiles">
        <div
            class="tile flex__between pointer"
            @click="setActive('booklets')"
            :class="[active === 'booklets' ? 'active' : null]"
          >
            <p class="text__bold text__md">Booklets</p>
            <font-awesome-icon icon="book-open" size="lg" class="ml-10" />
          </div>
          <div
            class="tile flex__between pointer"
            @click="setActive('b-cards')"
            :class="[active === 'b-cards' ? 'active' : null]"
          >
            <p class="text__bold text__md">Business Cards</p>
            <font-awesome-icon icon="address-card" size="lg" class="ml-10" />
          </div>
          
          <div
            class="tile flex__between pointer"
            @click="setActive('postcards')"
            :class="[active === 'postcards' ? 'active' : null]"
          >
            <p class="text__bold text__md">Postcards</p>
            <font-awesome-icon icon="mail-bulk" size="lg" class="ml-10" />
          </div>
          <div
            class="tile flex__between pointer"
            @click="setActive('flyers')"
            :class="[active === 'flyers' ? 'active' : null]"
          >
            <p class="text__bold text__md">Flyers</p>
            <font-awesome-icon icon="paper-plane" size="lg" class="ml-10" />
          </div>
          <div
            class="tile flex__between pointer"
            @click="setActive('trifolds')"
            :class="[active === 'trifolds' ? 'active' : null]"
          >
            <p class="text__bold text__md">Trifolds</p>
            <font-awesome-icon icon="map" size="lg" class="ml-10" />
          </div>
          <div
            class="tile flex__between pointer"
            @click="setActive('mailing')"
            :class="[active === 'mailing' ? 'active' : null]"
          >
            <p class="text__bold text__md">Mailing</p>
            <font-awesome-icon icon="shipping-fast" size="lg" class="ml-10" />
          </div>
        </section>
      </section>
    </section>
    <section class="container">
      <section class="pos__rel pricing-container">
        <div v-if="active === null">
          <Placeholder />
        </div>
        <div v-if="active === 'booklets'">
          <Booklets />
        </div>
        <div v-if="active === 'b-cards'">
          <BusinessCards />
        </div>
        <div v-if="active === 'flyers'">
          <Flyers />
        </div>
        <div v-if="active === 'mailing'">
          <Mailing />
        </div>
        <div v-if="active === 'postcards'" class="show-postcards">
          <PostCardsOne />
          <PostCardsTwo />
        </div>
        <div v-if="active === 'trifolds'">
          <Trifolds />
        </div>
      </section>
    </section>
    <section class="container">
      <section class="margin__md">
        <h2 class="text__center padding__sm">Ready to Order?</h2>
        <div class="flex__center padding__sm">
          <router-link to="/print-job" class="btn btn__yellow" name="New print job">Submit a Request</router-link>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import Placeholder from "./Placeholder";
import BusinessCards from "./BusinessCards";
import Booklets from "./Booklets";
import PostCardsOne from "./PostcardsOne";
import PostCardsTwo from "./PostcardsTwo";
import Flyers from "./Flyers";
import Trifolds from "./Trifolds";
import Mailing from "./Mailing";
export default {
  components: {
    Placeholder,
    BusinessCards,
    Booklets,
    PostCardsOne,
    PostCardsTwo,
    Flyers,
    Trifolds,
    Mailing
  },
  metaInfo: {
    title: "Pricing | Printzilla"
  },
  data() {
    return {
      active: null
    };
  },
  methods: {
    setActive(val) {
      this.active = val;
    }
  },
  mounted() {
    const type = this.$route.query ? this.$route.query.type : null;
    if (type) {
      this.active = type;
    }
  }
};
</script>

<style scoped lang="scss">
.banner {
  padding: 75px 0 150px 0;
  background: linear-gradient(rgba(71, 119, 61, 0.8), rgba(71, 119, 61, 0.8)),
    url("https://cdn.fbhl.com/Print-Center/assets/dino-shopping-min.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  h1,
  p {
    color: $powder;
  }
}
.product-tiles {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 25px);
  max-width: 700px;
  margin: auto;
  padding-top: 10px;
}
.tile {
  padding: 15px 10px;
  background-color: $frost;
  @include shadow;
  border-radius: 5px;
  p,
  svg {
    color: $pine;
  }
}
.active {
  background-color: $medallion;
  p,
  svg {
    color: $orange;
  }
}
.pricing-container {
  margin-top: -50px;
  margin-bottom: 75px;
}

.show-postcards {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
}
@media (min-width: $sm) {
  .product-tiles {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 25px;
  }
}
@media (min-width: $md) {
  .show-postcards {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .show-postcards {
    grid-column-gap: 50px;
  }
}
</style>