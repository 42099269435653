
const state = {
   navToggled: false
};

const getters = {
   getNavToggled(state) {
      return state.navToggled;
   }
};

const actions = {
   startSetNavToggled({ commit }, bool) {
      commit("SET_NAV_TOGGLED", bool);
   }
};

const mutations = {
   SET_NAV_TOGGLED(state, bool) {
      return state.navToggled = bool;
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}