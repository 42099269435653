import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";

import "./static/styles/main.scss";
import 'simplebar/dist/simplebar.min.css';
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

//plugins
import "./plugins/meta";
import "./plugins/fontawesome";
import "./plugins/v-mask";
import "./plugins/simplebar";
import "./plugins/scrollreveal";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
