<template>
  <div id="app">
    <Header />
    <section class="content">
      <router-view></router-view>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/includes/Header";
import Footer from "./components/includes/Footer";
export default {
  components: {
    Header, 
    Footer
  }
};
</script>

<style>
</style>
