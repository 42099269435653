<template>
  <section class="flex__center">
    <section class="pricing-select pos__rel pointer" @click="toggleSelectOpen">
      <p class="text__bold flex__center">
        {{ active ? active.name : "Select an option" }}
        <font-awesome-icon :icon="icon" size="sm" class="ml-10" />
      </p>
      <ul v-if="selectOpen" class="pricing-select--options">
        <li
          v-for="product in products"
          :key="product.link"
          @click="setActive(product)"
          class="pointer"
        >{{ product.name }}</li>
      </ul>
    </section>
  </section>
</template>

<script>
export default {
  props: ["products"],
  data() {
    return {
      selectOpen: false,
      active: null
    };
  },
  computed: {
    icon() {
      return this.selectOpen ? "caret-up" : "caret-down";
    }
  },
  methods: {
    toggleSelectOpen() {
      this.selectOpen = !this.selectOpen;
    },
    setActive(val) {
      this.active = val;
      this.$emit("setActive", val);
    }
  }
};
</script>

<style scoped lang="scss">
.pricing-select p {
  background-color: $frost;
  border-radius: 5px;
  padding: 15px 10px;
  width: 175px;
  cursor: pointer;
}
.pricing-select--options {
  @include pos-abs($top: calc(100% + 5px), $z: 2);
  @include grid($cols: 1fr);
  border-radius: 5px;
  background-color: $frost;
  height: 200px;
  overflow-y: scroll;
  li {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    &:hover {
      background-color: darken($frost, 10%);
    }
  }
}
</style>