<template>
  <section class="products-product">
    <h2 class="pb-15">{{ product.name }}</h2>
    <VueSlickCarousel v-bind="settings">
      <div
        class="carousel-slide"
        v-for="link in product.photos"
        :key="link"
        @click="setActive(link)"
      >
        <div
          class="carousel-slide--content"
          :style="`background-image: url('${link}');`"
          :alt="`${product.name} sample photo`"
        ></div>
      </div>
    </VueSlickCarousel>
    <div class="flex__right">
      <router-link :to="`/products/${product.link}`" class="link__none flex__right">
        View More
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </router-link>
    </div>
    <Zoom v-if="active" :active="active" :product="product" @exitModal="unsetActive" />
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import Zoom from "./Zoom";
export default {
  props: ["product"],
  components: {
    VueSlickCarousel,
    Zoom
  },
  data() {
    return {
      active: null,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 5,
        initialSlide: 0,
        focusOnSelect: false
      }
    };
  },
  methods: {
    setActive(link) {
      this.active = link;
    },
    unsetActive() {
      this.active = null;
    }
  }
};
</script>

<style scoped lang="scss">
.products-product {
  background-color: $frost;
  padding: 20px;
  border-radius: 20px;
}
.carousel-slide {
  height: 200px;
  cursor: pointer;
}
.carousel-slide--content {
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
</style>